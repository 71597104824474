/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/react@18.3.1/umd/react.development.js
 * - /npm/react-dom@18.3.1/umd/react-dom.development.js
 * - /npm/react-hook-form@7.47.0/dist/index.umd.min.js
 * - /npm/@babel/standalone@7.25.7/babel.min.js
 * - /npm/react-router-dom@5.3.4/umd/react-router-dom.min.js
 * - /npm/axios@1.7.7/dist/axios.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
